import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAuth0 } from "@auth0/auth0-react";

import logo from '../assets/Logo-Taxodian-Liggend-650x200px.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
    textAlign: 'left',
  width: '80%',
  padding: '24px'
}));

export default function FixedContainer() {
  const { loginWithRedirect } = useAuth0();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Box sx={{ height: '100vh', width: '100%' }}>
          <Grid container sx={{ height: '100%'}}>
            <Grid item md={6}>
              <Box sx={{ width: '100%', bgcolor: '#D2D0BA', height: '100%' }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: '100%'
                  }}>
                  <Box sx={{ width: '60%' }}>
                    <img src={logo} alt="Logo" width="100%" />
                  </Box>
                  <Box sx={{ width: '80%', marginTop: '48px' }}>
                    <Typography variant="overline" gutterBottom component="div">
                      Taxodian international withholding tax reclamation app
                    </Typography>
                    <Typography variant="caption" gutterBottom component="div">
                      The Taxodian app reclamation app helps you claim withholding tax in several countries worldwide. Simply fill in the forms, sign and we do the rest. For more information, e-mail us at info@taxodian.com.
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={6} sx={{ height: '100%' }} >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%'
                }}>
                <Item>
                  <Stack direction="column" spacing={3}>
                    <Button variant="outlined" disabled>
                      Sign up is closed
                    </Button>
                    <Button variant="outlined" onClick={() => loginWithRedirect({})}>
                      Login
                    </Button>
                    <Typography variant="subtitle2" gutterBottom component="div" align="center">
                      You will be redirected to the Multi-factor Auth login page
                    </Typography>
                  </Stack>
                </Item>
                <Box sx={{ marginTop: '24px' }}>
                  <Typography variant="caption" gutterBottom component="div">
                    Legal Reaction B.V. 2022, all rights reserved.
                  </Typography>
                </Box>
                <Button
                  // component={Link} 
                  variant="outlined" 
                  href="https://www.taxodian.com" 
                  sx={{ position: 'absolute', margin: '24px', bottom: 0, right: 0 }}>
                  Go to the Taxodian webpage
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
