import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
// const config = getConfig();

const providerConfig = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#5D576B",
    },
    secondary: {
      main: "#D80D5B"
    }
  },
});

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
